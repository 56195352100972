import React from 'react';
import styled from 'styled-components';
import { UniversalLink } from './UniversalLink';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
  frontmatter: {
    title: string;
    date: string;
    slug: string;
    excerpt: string;
    featuredImage: {
      childImageSharp: {
        fixed?: any;
        fluid?: any;
      };
    };
  };
}

const PPWrapper = styled.div`
  background-color: var(--surface);
  display: grid;
  transition: background-color 500ms ease-in-out;
`;
const PPInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr;
  justify-content: space-between;
  align-self: stretch;
  justify-self: stretch;
`;
const PPHeader = styled.div``;
const PPHeaderText = styled.h3`
  font-size: var(--font-size-xl);
  margin: 0;
`;

const PPBody = styled.div`
  padding: 1rem;
`;
const PPFooter = styled.div`
  padding: 1rem;
  align-self: center;
  justify-self: end;
`;

export const PostPreview = ({ frontmatter }: Props) => {
  return (
    <PPWrapper>
      <PPInner>
        <PPHeader>
          <UniversalLink to={frontmatter.slug} label={frontmatter.title}>
            <Img
              style={{ maxHeight: '200px', width: 'auto' }}
              imgStyle={{ objectFit: 'cover' }}
              alt={frontmatter.title}
              fluid={frontmatter.featuredImage.childImageSharp.fluid}
            />
          </UniversalLink>
        </PPHeader>
        <PPBody>
          <PPHeaderText>
            <UniversalLink to={frontmatter.slug}>
              {frontmatter.title} &rarr;
            </UniversalLink>
          </PPHeaderText>
          <p>{frontmatter.excerpt}</p>
        </PPBody>
      </PPInner>
    </PPWrapper>
  );
};
