import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { UniversalLink } from '../components/UniversalLink';
import { ContainerWrapper } from '../components/styled/Container';
import { SEO } from '../components/SEO';
import { PostPreview } from '../components/PostPreview';

interface Post {
  node: {
    timeToRead?: number;
    frontmatter: {
      title: string;
      date: string;
      slug: string;
      excerpt: string;
      featuredImage: {
        childImageSharp: {
          fixed?: any;
          fluid?: any;
        };
      };
    };
  };
}

interface Props {
  data: {
    allMdx: {
      edges: Post[];
    };
  };
}

const PostWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  gap: 3rem;
`;

const PageWrapper = styled(ContainerWrapper)`
  display: grid;
  gap: 3rem;
`;

const Intro = styled(ContainerWrapper)`
  min-height: 25vh;
  display: grid;
  align-content: center;
  justify-content: center;
`;

const blog = ({ data }: Props) => {
  const posts = data.allMdx.edges;
  const text = `This is a blog. You've probably heard about them before. In fact,
  you're probably read some before. This one is different. I write here
  and no one else writes like I do. You'll love it.`;
  return (
    <PageWrapper align='center' width='page'>
      <SEO title='The Blog' description={text} />
      <Intro align='center' width='text'>
        <p>{text}</p>
        <small>
          Disclaimer: Due to the individual nature of human beings, you may or
          may not love it.
        </small>
      </Intro>
      <PostWrapper>
        {posts.map((post) => (
          <PostPreview
            key={post.node.frontmatter.slug}
            frontmatter={post.node.frontmatter}
          />
        ))}
      </PostWrapper>
    </PageWrapper>
  );
};

export default blog;

export const BlogPageQuery = graphql`
  query AllBlogQuery {
    allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            slug
            date
            excerpt
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
